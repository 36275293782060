import styled from "styled-components";

const primaryColor = "#9c3431";

const GreyHeader = styled.div`
  background: #535b73;
  height: 5px;
`;
const Title = styled.a`
  text-decoration: none;
  &:visited {
    color: white;
  }
`;

const StyledA = styled.a`
  height: 5px;
  color: white;
  text-decoration: none;
  :visited {
    color: white;
  }
  padding: 5px;
  border: black solid 1px;
  border-radius: 10px;
  background: ${primaryColor};
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transition: box-shadow 500ms;
  &:hover,
  :focus {
    box-shadow: 0 0.5em 0.5em -0.4em white;
    transform: translateY(-0.25em);
    transition: 500ms;
  }
`;

const Header = styled.div`
  border-top: #b0a5b1 solid 2px;
  border-bottom: #b0a5b1 solid 2px;
  padding: 10px;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  font-family: Helvetica, sans-serif;
  background: #9c3431;
  text-align: center;
`;

const TopInfomation = styled.div`
  text-align: center;
  margin: 20px 0;
`;
const TopInfomationText = styled.p`
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-break: auto;
  background: white;
  font-weight: bold;
  margin: 20px 0;
  padding: 5px;
  margin: 10px;
  display: inline;
  border: black solid 1px;
  border-radius: 10px;
`;

const Infomation = styled.div`
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-break: auto;
  font-weight: bold;
  padding-bottom: 30px;
`;

const OpeningHours = styled.table`
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  margin: 25px 0;
  color: white;
  padding-left: 5%;
`;

const OpeningHoursDiv = styled.div`
  background: #9c3431;
  width: 100%;
`;

const WhiteBackground = styled.div`
  background: #f4f4f4;
`;
const RedBackground = styled.div`
  background: ${primaryColor};
`;

const Copyright = styled.div`
  text-align: center;
  font-size: 8pt;
  padding-bottom: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
`;

function App() {
  return (
    <div>
      <WhiteBackground>
        <GreyHeader />
        <Header>
          <Title href="/">Duris Autocenter</Title>
        </Header>
        <GreyHeader />
        <TopInfomation>
          <TopInfomationText>AUTOVÆRKSTED I ROSKILDE CENTRUM</TopInfomationText>
        </TopInfomation>
        <TopInfomation>
          <TopInfomationText>
            SALG & BYTTE AF NYERE BRUGTE BILER
          </TopInfomationText>
        </TopInfomation>
        <Infomation>
          <div>
            <StyledA href="tel:+4546350444" target="_blank">
              ☎️ 46 350 444
            </StyledA>
          </div>
          <br />
          <div>
            <StyledA href="mailto:duris@danbbs.dk" target="_blank">
              📮 duris@danbbs.dk
            </StyledA>
          </div>
          <br />
          <div>
            <StyledA
              href="https://www.bilbasen.dk/find-en-forhandler/bilforhandler-duris-autocenter-id1286"
              target="_blank"
            >
              🚗 Vores biler på Bilbasen
            </StyledA>
          </div>
          <br />
          <div>
            <StyledA
              href="https://goo.gl/maps/8JMvRtF9N1obAPzq8"
              target="_blank"
            >
              📍 Ringstedgade 42, 4000 Roskilde
            </StyledA>
          </div>
        </Infomation>
      </WhiteBackground>
      <RedBackground>
        <OpeningHoursDiv>
          <OpeningHours>
            <thead>
              <tr>
                <th>
                  <span>Åbningstider </span>
                </th>
                <th>⏱</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Mandag</th>
                <td>08:00 - 16:00</td>
              </tr>
              <tr>
                <th>Tirsdag</th>
                <td>08:00 - 16:00</td>
              </tr>
              <tr>
                <th>Onsdag</th>
                <td>08:00 - 16:00</td>
              </tr>
              <tr>
                <th>Torsdag</th>
                <td>08:00 - 16:00</td>
              </tr>
              <tr>
                <th>Fredag</th>
                <td>08:00 - 14:00</td>
              </tr>
              <tr>
                <th>Lørdag</th>
                <td>LUKKET</td>
              </tr>
              <tr>
                <th>Søndag</th>
                <td>LUKKET</td>
              </tr>
              <tr>
                <th>Helligdage</th>
                <td>LUKKET</td>
              </tr>
            </tbody>
          </OpeningHours>
        </OpeningHoursDiv>
      </RedBackground>
      <Copyright>© 1998 - {new Date().getFullYear().toString()}</Copyright>
    </div>
  );
}

export default App;
